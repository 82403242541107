.doc-body {
    display: flex;
    min-height: 400px;

    .doc-category {
        width: 180px;
        margin-right: 20px;
        overflow-y: scroll;
        overflow-x: hidden;
        height: calc(~"100vh - 230px");
    }

    .doc-list {
        flex-grow: 1;
    }
}

.article-list td {
    padding: 8px 16px !important;

    .article-title {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}
@primary-color: #7f7fd5;@link-color: #86a8e7;